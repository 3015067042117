<template>
  <div class="buildings">
    <MobileTable
      label="Listado de personal del complejo"
      :linked="true"
      :columns="personnel.columns"
      :data="store.state.personnel.personnelList"
      :onSelect="onDetail"
    />
    <DesktopTable
      label="Listado de personal del complejo"
      :linked="true"
      :columns="personnel.columns"
      :data="store.state.personnel.personnelList"
      :onSelect="onDetail"
    />
    <Actions :items="personnel.actions" />
  </div>
  <BasicModal v-model:visible="personnel.showDialog">
    <template v-slot:dialog>
      <BasicTitle :title="personnel.title" />

      <MobileTable
        label="Listado de Entradas / Salidas"
        :columns="personnel.records.columns"
        :data="store.state.personnel.records"
      />
      <DesktopTable
        label="Listado de Entradas / Salidas"
        :columns="personnel.records.columns"
        :data="store.state.personnel.records"
      />
    </template>
  </BasicModal>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import BasicTitle from "../../widgets/title/BasicTitle.vue";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions, BasicTitle, BasicModal },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onLicense = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/horario/licencias/listado");
    };
    const onDetail = async (data) => {
      await store.dispatch(actions.personnelActions.records, {
        personnelId: data.id,
        buildingId: store.state.general.selectedBuilding,
      });
      console.log(data);
      personnel.showDialog = true;
    };

    const personnel = reactive({
      showDialog: false,
      title: "Registros de Nombre del personal",
      records: {
        columns: [
          {
            field: "date",
            header: "Fecha",
            center: true,
            date: true,
          },
          {
            field: "fromTime",
            header: "Hora de Entrada",
            center: true,
            time: true,
          },
          {
            field: "toTime",
            header: "Hora de Salida",
            center: true,
            time: true,
          },
        ],
      },
      columns: [
        {
          field: "name",
          header: "Nombre",
          sort: true,
          center: true,
        },
        {
          field: "identity",
          header: "Rut / CI",
        },
        {
          field: "email",
          header: "Email",
        },
        {
          field: "phone",
          header: "Teléfono",
        },
        {
          field: "personnelType",
          header: "Cargo",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Licencias",
          action: onLicense,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/horario/licencias/listado"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedPersonnelList", []);
      store.commit("setBreadcrumbs", [
        { label: "Inicio", to: "/home" },
        { label: "Registro de Horario", to: route.path },
      ]);
      await store.dispatch(
        actions.personnelActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, personnel, onDetail };
  },
};
</script>

<style scoped></style>
